import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import TraCheckout from "../../../components/TraCheckout";


function SubscribeButtonTra(props) {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();
  const [checkoutModal, setCheckoutModal] = useState(false)

  if (!isLoading && isAuthenticated) {
    return (
      <div style={{ margin: "auto" }}>
        {props.activePlanLevel !== undefined && 
        (checkoutModal ? <TraCheckout plan_obj={props.plan_obj} isMobile={props.isMobile} setCheckoutModal={setCheckoutModal} plan={props.plan} monthly={props.monthly} price={props.price}/> : 
        <button
          className="button is-rounded is-size-6-mobile is-size-6-tablet"
          style={{ backgroundColor: "#32CD32", margin: "auto", color: "#ffffff" }}
          onClick={() =>
            setCheckoutModal(true)
          }
        >
          Start Free Trial!
        </button>)}
      </div>
    );
  } else {
    return (
      <button
        className="button is-rounded is-size-6-mobile is-size-6-tablet"
        style={{ backgroundColor: "#32CD32", margin: "auto", color: "#ffffff" }}
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: "signup",
            },
            appState: { returnTo: "/pricing?s=1" },
          })
        }
      >
        Sign up for Free!
      </button>
    );
  }
}

export default SubscribeButtonTra;
