import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay } from "swiper/modules";

import CarCard from "./CarCard";
import CardSwipper from "../../Products/Mobile/CardSwipper";

function Carousel(props) {
  const [data, setData] = useState(Array(10).fill(0));

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  useEffect(() => {
    const loadProducts = async () => {
      fetch("https://server.nepeto.com/main_page_products", {
        mode: "cors",
      })
        .then((response) => response.json())
        .then((data) => {
          shuffleArray(data)
          setData(data);
        });
    };
    loadProducts();
  }, []);

  return props.isMobile ? (
    data.includes(0) ? (
      <div
        style={{
          height: "50vh",
          width: "70vw",
          backgroundColor: "#e6cede",
          borderRadius: "20px",
          margin: "auto",
        }}
        className="defFont"
      >
        <i
          className="fa fa-spinner fa-spin"
          aria-hidden="true"
          style={{ margin: "70% 48%" }}
        ></i>
      </div>
    ) : (
      <div style={{ maxWidth: "90vw", overflow: "hidden" }}>
        <CardSwipper homePage={true} data={data} />
      </div>
    )
  ) : (
    <div style={{ backgroundColor: "#773c7e" }}>
      <Swiper
        centeredSlides={true}
        slidesPerView={4}
        initialSlide={3}
        loop={data[0] !== 0}
        autoplay={{
          delay: 500,
          disableOnInteraction: true,
        }}
        speed={2000}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide>
              <CarCard key={index} loading={item === 0} product={item} />;
            </SwiperSlide>
          );
        })}
        {/* <CardSwipper data={data}/> */}
      </Swiper>
    </div>
  );
}

export default Carousel;
