import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import SubscribeButtonPayPal from '../pages/Pricing/components/SubscribeButtonPayPal';

function TraCheckout(props) {
  const [number, SetNumber] = useState("");
  const [name, SetName] = useState("");
  const [date, SetDate] = useState("");
  const [cvc, SetCvc] = useState("");
  const [focus, SetFocus] = useState("");
  const [coupon, setCoupon] = useState(false);
  const [failed, setFailed] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (coupon !== false) {
      if (coupon.includes("10")) {
        setDiscountedPrice((props.price * 0.9).toFixed(2));
      } else if (coupon.includes("20")) {
        setDiscountedPrice((props.price * 0.8).toFixed(2));
      } else if (coupon.includes("30")) {
        setDiscountedPrice((props.price * 0.7).toFixed(2));
      } else if (coupon.toUpperCase().includes("7FIGUREGRAMMY") || coupon.toUpperCase().includes("SILENTJIM")) {
        setDiscountedPrice((props.price * 0.5).toFixed(2));
      } else {
        setDiscountedPrice(0);
      }
    }
  }, [coupon])

  const checkout = async (e) => {
    if (number && name && date && cvc) {
      e.target.innerHTML = "LOADING...";
      e.target.disabled = true;
      const accessToken = await getAccessTokenSilently();
      await fetch(`https://server.nepeto.com/trasubscribe/`, {
        mode: "cors",
        method: "POST",
        headers: {
          Accept: "application/json; charset=utf8",
          "Content-Type": "application/json; charset=utf8",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          card: number,
          name: name,
          date: date,
          cvc: cvc,
          price: (discountedPrice ? discountedPrice : props.price) * (props.monthly ? 1 : 12),
          plan: props.plan + " Plan",
          monthly: props.monthly ? 1 : 0
        })
      }).then(response => response.json())
        .then(data => {
          if (data === "Failed Validation") {
            e.target.innerHTML = "TRY AGAIN";
            e.target.disabled = false;
            setFailed(true);
          } else {
            window.location.href = data;
            props.setCheckoutModal(false);
          }
        }).catch(error => {
          setFailed(true);
        })
    } else {
      alert("One of the required fields is missing.")
    }
  };

  return (
    <>
      <div
        id="modal-js-example"
        className={"modal is-active"}
        style={{ zIndex: 99999999 }}
      >
        <div className="modal-background"></div>

        <div
          className="modal-content"
          style={{ minWidth: props.isMobile ? "90vw" : "55vw" }}
        >
          <div className="box" style={{ textAlign: "center" }}>
            <br />
            <div className="columns" style={{ height: "auto", width: props.isMobile ? "100%" : "85%", margin: "auto" }}>              <div
              className="column is-one-third"
              style={{
                boxShadow: "2px 0px 5px 0px gray",
                borderRadius: "15px 0px 0px 15px",
                borderRight: "1px solid lightgray",
                backgroundColor: "#8B3C7E",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                color: "#FFFFFF"
              }}
            >
              <h1 style={{ fontSize: "170%", fontWeight: "bold", marginBottom: "15%" }}>
                {props.plan} Plan
              </h1>
              <h2 style={{ fontSize: "140%", marginBottom: "15%", color: discountedPrice ? "darkred" : "white" }}>
                <span style={{ textDecoration: discountedPrice && "line-through" }}>${props.price * (props.monthly ? 1 : 12)}/{props.monthly ? "month" : "year"}</span>
                {discountedPrice ? <span style={{ fontSize: "120%", marginBottom: "15%", color: discountedPrice ? "#48C78E" : "white", fontWeight: "600" }}>
                  <br />${discountedPrice * (props.monthly ? 1 : 12)}/{props.monthly ? "month" : "year"}
                </span> : <></>}
              </h2>
              <p style={{ fontSize: "130%", marginBottom: "5%" }}>
                14-day free trial
              </p>
              <p style={{ fontSize: "130%", marginBottom: "15%" }}>
                First charge: {new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}
              </p>
              {/* Coupon Code Input */}
              <br />
              {coupon === false ? <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setCoupon("")}>Apply coupon code</span> : <p className="control has-icons-left">
                <input
                  className="input"
                  placeholder="Coupon Code"
                  type="text"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                <span className="icon is-small is-left">
                  <i className="fa fa-tag"></i>
                </span>
              </p>}
            </div>
              {failed ?
                <div className="column is-two-third" style={{ boxShadow: "2px 0px 5px 0px gray", borderRadius: "0px 15px 15px 0px", backgroundColor: "#e6cede" }}>
                  <span style={{fontWeight: "600", fontSize: "125%"}}><span style={{color: "red"}}>Couldn't verify your credit card.</span> Please try again, use a different card or use PayPal below. 
                    If you're unable to proceed with any card, please contact support, and they will assist you as soon as possible.
                    Thank you!</span>
                    <br/><br/>
                  <button onClick={() => setFailed(false)} className='button is-success' style={{boxShadow: "0px 0px 5px 0px black", fontWeight: "600", fontSize: "125%"}}>
                    TRY AGAIN
                  </button>
                  <br/><br/><br/><br/>
                  <SubscribeButtonPayPal
                    plan={props.plan_obj.name}
                    plan_id={props.monthly ? props.plan_obj.plan_id_monthly : props.plan_obj.plan_id_annually}
                    planLevel={props.plan_obj.planLevel}
                    monthly={props.monthly}
                    activePlanLevel={1}
                    price={Math.ceil((props.monthly ? props.plan_obj.price_monthly : props.plan_obj.price_annually))}
                  />
                </div>
                :
                <div className="column is-two-third" style={{ boxShadow: "2px 0px 5px 0px gray", borderRadius: "0px 15px 15px 0px", backgroundColor: "#e6cede" }}>
                  <div style={{ margin: "auto", paddingTop: props.isMobile && "3%" }}>
                    <Cards
                      number={number}
                      name={name}
                      expiry={date}
                      cvc={cvc}
                      focused={focus}
                    />

                    <br />
                    <p class="control has-icons-left" style={{ width: "290px", margin: "auto" }}>
                      <input
                        className="input"
                        type="text"
                        placeholder='Card Number'
                        value={number}
                        name="number" onChange={(e) => {
                          SetNumber(e.target.value);
                        }}
                        onFocus={(e) => SetFocus(e.target.name)} />
                      <span class="icon is-small is-left">
                        <i class="fa fa-credit-card-alt"></i>
                      </span>
                    </p>
                    <br />
                    <p class="control has-icons-left" style={{ width: "290px", margin: "auto" }}>
                      <input
                        className="input"
                        placeholder='Name'
                        type="text"
                        value={name}
                        name="name"
                        onChange={(e) => {
                          SetName(e.target.value);
                        }}
                        onFocus={(e) => SetFocus(e.target.name)} />
                      <span class="icon is-small is-left">
                        <i class="fa fa-user"></i>
                      </span>
                    </p>
                    <br />
                    <p class="control has-icons-left" style={{ width: "290px", margin: "auto" }}>
                      <input
                        className="input"
                        placeholder='Expiration Date (MM/YY)'
                        type="text"
                        name="expiry"
                        value={date}
                        onChange={(e) => {
                          SetDate(e.target.value);
                        }}
                        onFocus={(e) => SetFocus(e.target.name)} />
                      <span class="icon is-small is-left">
                        <i class="fa fa-calendar-o"></i>
                      </span>
                    </p>
                    <br />
                    <p class="control has-icons-left" style={{ width: "290px", margin: "auto" }}>
                      <input
                        className="input"
                        placeholder='CVV'
                        type="tel"
                        name="cvc"
                        value={cvc}
                        onChange={(e) => {
                          SetCvc(e.target.value);
                        }}
                        onFocus={(e) => SetFocus(e.target.name)} />
                      <span class="icon is-small is-left">
                        <i class="fa fa-lock"></i>
                      </span>
                    </p>
                  </div>
                  <br />
                  <button
                    className="button is-rounded is-success"
                    style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                    onClick={(e) => {
                      checkout(e);
                    }}
                  >
                    Proceed&emsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </button>
                  <br />
                  {props.isMobile && <br />}
                  <div style={{ float: "right" }}>
                    <span style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                      <img style={{ width: props.isMobile ? "13vw" : "2vw", mixBlendMode: "multiply" }} src="https://direct.tranzila.com/Tranzila_files/mcafee.png" />
                      <img style={{ width: props.isMobile ? "13vw" : "2vw", mixBlendMode: "multiply" }} src="https://direct.tranzila.com/Tranzila_files/pci.png" />
                      <img style={{ width: props.isMobile ? "13vw" : "2vw", mixBlendMode: "multiply" }} src="https://direct.tranzila.com/Tranzila_files/trusted-site-seal.png" />
                    </span>
                    <a style={{ fontSize: props.isMobile ? "3.3vw" : "0.6vw", marginTop: "1%" }} href='https://www.tranzila.com/english.html' target="_blank" rel="noreferrer">Powered by Tranzila ©</a>
                  </div>
                </div>}
            </div>
            <span style={{ fontSize: props.isMobile ? "3vw" : "0.7vw" }}>We may temporarily charge you $1 to verify your credit card.</span>
            <br /><br />
            {props.isMobile && <br />}
            <div className="columns is-mobile is-centered">
              <button
                style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw", border: "none", backgroundColor: "transparent", color: "red", cursor: "pointer" }}
                onClick={() => {
                  props.setCheckoutModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TraCheckout;
