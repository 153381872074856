import React, { useEffect } from "react";
import "../Pricing.css";
import PriceCard from "../components/PriceCard";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Carousel from "../../Home/components/CarouselV2";

function JimPricing(props) {
  const { isAuthenticated, isLoading } = useAuth0();
  const prices = [
    {
      name: "Starter",
      price_monthly: 28.90,
      price_annually: 22.10,
      img: "/assets/starterFlower.png",
      checkList: [
        "Profitable products from Retail Suppliers (Walmart, Target, Kohl's, Zoro, Home Depot, etc.)",
        "Profitable products from more than 60 Unique Distributors",
        "7 Days Free Trial",
      ],
      planLevel: 1
    },
    {
      name: "Advanced",
      price_monthly: 66.30,
      price_annually: 52.70,
      img: "/assets/advancedFlower.png",
      checkList: [
        "Everything from the previous plans",
        "Profitable products from Wholesale Suppliers",
        "Profitable products that are currently Out-of-Stock on Amazon",
        "Amazon Flips (A2A)",
        "Advanced Product Data",
        "7 Days Free Trial",
      ],
      planLevel: 2
    },
    {
      name: "Pro",
      price_monthly: 83.30,
      price_annually: 66.30,
      img: "/assets/proFlower.png",
      checkList: [
        "Everything from the previous plans",
        "Telegram, Bundles, Liquiditions (Soon)",
        "Amazon to Walmart *NEW*",
        "Pro Data *NEW*",
        "Pro Features *NEW*: Filter By Profit, and Sort By 'Found Time'",
        "Export Nepeto's products to CSV",
        "7 Days Free Trial",
      ],
      planLevel: 3
    },
    {
      name: "Enterprise",
      price_monthly: "Contact Us",
      price_annually: "Contact Us",
      img: "/assets/enterpriseflower.png",
      isEnterprise: true,
      checkList: [
        "Everything from the previous plans",
        "Export 10,000 Nepeto's products to CSV (Lead List)",
        "Scanner [Beta] - Find products from given CSV",
        "You can prioritize adding specific suppliers to Nepeto",
        "Discounted prep services",
        "Personal sales representative",
      ],
      planLevel: -1
    },
  ];
  const [isMonthly, setIsMonthly] = useState(true);
  const [activePlanLevel, setActivePlanLevel] = useState(0);

  useEffect(() => {
    const getActivePlan = async () => {
      if (props.activePlanLevel === "Starter Plan") {
        setActivePlanLevel(1)
      } else if (props.activePlanLevel === "Advanced Plan") {
        setActivePlanLevel(2)
      } else if (props.activePlanLevel === "Pro Plan") {
        setActivePlanLevel(3)
      } else {
        setActivePlanLevel(parseInt(props.activePlanLevel))
      }
    };
    if (!isLoading && isAuthenticated) {
      getActivePlan();
    }
  }, [props.activePlanLevel, isLoading, isAuthenticated])

  return (
    <div className="container">
      <Helmet>
        <title>Nepeto - Pricing</title>
        <meta
          name="description"
          content="Explore our pricing options and choose the plan that best suits your needs. Discover transparent and competitive pricing for our products and services. Find the perfect pricing plan for your requirements."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, pricing, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div class="columns center" style={{ margin: "8vh 5vh 5vh 5vh", height: "10vh", textAlign: "center" }}>
        <div style={{ textAlign: "center" }}>
          <img
            src="/assets/jim.jpg"
            alt="sale"
            style={{
              width: props.isMobile ? "10vh" : "calc(3.5vw + 5vh)",
              borderRadius: "500px",
            }}
          /><br />
          <strong style={{ color: "rgb(140, 62, 124)", fontSize: "calc(1vw + 1vh)" }}>Silent Jim</strong>
        </div>
        <div style={{ textAlign: "center" }}>
        <strong style={{ color: "rgb(140, 62, 124)", textAlign: "center", marginLeft: "4vw", marginRight: "3vw", fontSize: "calc(3.5vw + 1vh)" }}>50% OFF!</strong><br/>
        {!props.isMobile && <strong style={{ color: "rgb(140, 62, 124)", textAlign: "center", marginLeft: "4vw", marginRight: "3vw", fontSize: "calc(1.5vw + 1vh)" }}>Exclusive Discount</strong>}
        </div>
        <div style={{ textAlign: "center" }}>
          <img
            src="/assets/jimmy.jpg"
            alt="sale"
            style={{
              width: props.isMobile ? "10vh" : "calc(3.5vw + 5vh)",
              borderRadius: "500px",
            }}
          /><br />
          <strong style={{ color: "rgb(140, 62, 124)", fontSize: "calc(0.7vw + 1vh)" }}>@AskJimmySmith</strong>
        </div>
      </div>
      <br/>
      <h1 style={{ fontSize: "150%", textAlign: "center", color: "rgb(140, 62, 124)" }}>To apply this amazing lifetime discount, please use the coupon code: "SILENTJIM" !</h1>

      <div
        className="mx-5 aharoni"
        style={{
          marginTop: props.isMobile ? "10%" : "3%",
          marginBottom: props.isMobile ? "30%" : "3.5%",
          textAlign: !props.isMobile && "center",
        }}
      >
        <p
          className="is-size-2-mobile is-size-1-tablet"
          style={{ color: "#8c3e7c" }}
        >
          <strong style={{ color: "#8c3e7c" }}>Automated</strong> Product
          Sourcing for Amazon
        </p>
        <p
          className="is-size-6-mobile is-size-5-tablet"
          style={{ color: "#8c3e7c" }}
        >
          Our algorithm scans hundreds of{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
            }}
          >
            retailers
          </span>
          , unique{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
            }}
          >
            distributors
          </span>
          , and{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
            }}
          >
            wholesale
          </span>{" "}
          suppliers,
          <br />
          providing you with a selection of thousands of{" "}
          <span style={{ backgroundColor: "#e6cede", color: "#8c3e7c" }}>
            profitable products
          </span>{" "}
          ready for resell on Amazon!{" "}
        </p>
        <br />
        <div className="container custom-carousel" style={{ marginTop: "2vh" }}>
          <Carousel isMobile={props.isMobile} />
        </div>
        <br />
      </div>

      <p style={{ textAlign: "center", color: "#8c3e7c", width: props.isMobile ? "90%" : "60%", margin: "auto", marginBottom: props.isMobile && "2vh" }}>Here’s how coach Joseph Kelsey on the <a href="https://JimCockrumCoaching.com" alt="jim" target="_blank" rel="noreferrer">JimCockrumCoaching.com</a> team is using Nepeto to find winning ASINs</p>
      <iframe
        src="https://www.youtube-nocookie.com/embed/PVizReWCDBo?si=bOVYweyc9cZXSMyS"
        title="YouTube video player"
        style={{
          margin: "auto",
          marginLeft: props.isMobile ? "5%" : "20%",
          marginBottom: props.isMobile ? "15vh" : "7vh",
          border: "2px solid #8c3e7c",
          padding: "1%",
          width: props.isMobile ? "90%" : "60%",
          height: props.isMobile ? "50vw" : "25vw",
        }}
        frameborder="0"
        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
}
export default JimPricing;
