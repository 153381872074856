import React, { useState, useEffect } from "react";
import ModalForHide from "../../Products/Components/ModalForHide";
import NotDirectLinkPopup, {
  warningSuppliers,
} from "../../Products/Components/NotDirectLinkPopup";
import { OOSbyZIPSuppliers } from "../../Products/Components/OOSbyZIP";
import DynamicKeepaGraphPopup from "../../../components/DynamicKeepaGraphPopup";
import OOSAdvancedDataModal from "./OOSAdvancedDataModal";
import MessageBeforeLinkPopup from "../../Products/Components/MessageBeforeLinkPopup";
import { signupDiscountSuppliers } from "../../Products/Components/RegisterForDiscount";
import FavoritesIcon from "../../Products/Components/FavoritesIcon";
import { useAuth0 } from "@auth0/auth0-react";

export default function OOSProductsTable(props) {
  const [bsr_state, setBsr] = useState(
    localStorage.getItem("DKGbsr") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGbsr"))
  );
  const [az_state, setAz] = useState(
    localStorage.getItem("DKGaz") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGaz"))
  );
  const [new_state, setNew] = useState(
    localStorage.getItem("DKGnew") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGnew"))
  );
  const [bb_state, setBb] = useState(
    localStorage.getItem("DKGbb") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGbb"))
  );
  const [fba_state, setFba] = useState(
    localStorage.getItem("DKGfba") === null
      ? true
      : JSON.parse(localStorage.getItem("DKGfba"))
  );
  const [range_state, setRange] = useState(
    localStorage.getItem("DKGrange") === null
      ? 90
      : localStorage.getItem("DKGrange")
  );
  const [hideModalProduct, setHideModalProduct] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [advancedDataProduct, setAdvancedDataProduct] = useState(null);
  const [DKGasin, setDKGAsin] = useState(null);
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
  const [loginWAZ, setLoginWAZ] = useState(false);
  const [gatedStatus, setGatedStatus] = useState({});
  const [showMessageObj, setShowMessageObj] = useState(null);
  const mainProductPage = ["big5sportinggoods", "dillards", "famousfootwear", "kohls", "dickssportinggoods", "boscovs", "jcpenney", "fivebelow", "nike", "belk", "underarmour", "crocs", "champssports", "sephora", "rei", "joann"]
  const categorySalesRankCount = { 'Climate Pledge Friendly': 1500000, 'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515, 'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843, 'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162, 'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647, 'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328, 'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340, 'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048, 'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515, 'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789, 'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893, 'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107, 'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894, "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127, "Collectible Coins": 210648, "Camera & Photo": 4021127 }

  useEffect(() => {
    const checkGated = async (asin) => {
      if (props.sellerId) {
        await fetch(`https://server.nepeto.com/mobile_app_check_restrictions/`, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sellerId: props.sellerId,
            asin: asin,
            refreshToken: props.refreshToken,
          })
        }).then(response => response.json())
          .then(data => {
            setGatedStatus((prevGS) => ({
              ...prevGS,
              [asin]: data,
            }));
          })
      }
    };

    if (!isLoading && isAuthenticated && props.data && props.data.length > 0 && props.sellerId !== "none" && props.sellerId !== "") {
      props.data.forEach(prd => { checkGated(prd.asin.split("/")[prd.asin.split("/").length - 1]) });
    }
    if (!isLoading && isAuthenticated && props.data && props.data.length > 0 && localStorage.getItem("azlog_pop_first") !== "1") {
      setLoginWAZ(true);
      localStorage.setItem("azlog_pop_first", "1");
    }
  }, [isLoading, isAuthenticated, props.data]);

  const openSuppLink = (product) => {
    if (!warningSuppliers.includes(product.source_name)) {
      window.open(
        product.url.replace(
          "X".repeat(product.source_name.length),
          product.source_name
        )
      );
    } else {
      setLinkUrl(
        product.url.replace(
          "X".repeat(product.source_name.length),
          product.source_name
        )
      );
    }
  };

  return (
    <>
      <div className="table-container products-table-container">
        <table className="table is-hoverable products-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Category</th>
              <th>BSR</th>
              <th>ASIN</th>
              <th>MOQ</th>
              <th>Price</th>
              <th>Dynamic Keepa Graph</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((product, index) => {
              return (
                <tr key={index}>
                  <td style={{ width: "22vw" }}>
                    <div style={{ display: "flex" }}>
                      <div className="amazonImage">
                        <p
                          onClick={() => {
                            window.open(product.asin + "?th=1&psc=1");
                          }}
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          Amazon
                        </p>
                        <img
                          alt="product_image"
                          className="hoverImageTable"
                          loading="lazy"
                          src={(product.az_img !== null && product.az_img !== "") ?
                            product.az_img :
                            `https://images.amazon.com/images/P/${product.asin.split("/")[product.asin.split("/").length - 1]}.jpg`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/assets/noimage.png";
                          }}
                          style={{
                            borderRadius: "4.5vw",
                            border: "5px solid #E3D0DF",
                            height: "4.5vw",
                            width: "4.5vw",
                            objectFit: "scale-down",
                            position: "relative",
                            zIndex: "5"
                          }}
                          onClick={() => {
                            window.open(product.asin + "?th=1&psc=1");
                          }}
                        />
                      </div>
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "10vw",
                          height: "10vh",
                          padding: "9px",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        {product.title.split(" | At")[0].slice(0, 40) !== ""
                          ? product.title.split(" | At")[0].slice(0, 40) + "..."
                          : product.asin.split("/")[
                          product.asin.split("/").length - 1
                          ]}
                      </span>
                      <div className="sourceImage">
                        <p
                          onClick={() => {
                            let message = "";
                            if (mainProductPage.includes(product.source_name)) {
                              message +=
                                "You will see the main product page.\nYou will need to set the correct variation yourself.\n..." + product.title.split(" | At")[0].slice((product.title.split(" | At")[0].length / 2).toFixed(0)) + "<br/>";
                            }
                            if (
                              signupDiscountSuppliers.includes(
                                product.source_name
                              )
                            ) {
                              message +=
                                "To receive the lowest price, this supplier requires you to sign up to their website.";
                            }
                            if (
                              OOSbyZIPSuppliers.includes(
                                product.source_name
                              )
                            ) {
                              message +=
                                "\nIf the product is out of stock - Change the ZIP CODE!\n";
                            }
                            if (message !== "" && (localStorage.getItem("showSuppMesssage" + product.source_name) === 'true' || localStorage.getItem("showSuppMesssage" + product.source_name) === null)) {
                              setShowMessageObj({
                                message: message,
                                messageAction: openSuppLink,
                                messageActionArg: product,
                                source: product.source_name
                              });
                            } else {
                              openSuppLink(product);
                            }
                          }}
                          style={{
                            textAlign: "center",
                            maxWidth: "4.5vw",
                            cursor: "pointer",
                          }}
                        >
                          {product.source_name}
                        </p>
                        <img
                          alt="product_image"
                          className="hoverImageTable"
                          loading="lazy"
                          src={product.img}
                          title={product.source_name === "dickssportinggoods" ? "The variations may vary" : ""}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/assets/noimage.png";
                          }}
                          style={{
                            borderRadius: "4.5vw",
                            border: "5px solid #E3D0DF",
                            height: "4.5vw",
                            width: "4.5vw",
                            objectFit: "scale-down",
                            position: "relative",
                            zIndex: "5"
                          }}
                          onClick={() => {
                            let message = "";
                            if (mainProductPage.includes(product.source_name)) {
                              message +=
                                "You will see the main product page.\nYou will need to set the correct variation yourself.\n..." + product.title.split(" | At")[0].slice((product.title.split(" | At")[0].length / 2).toFixed(0)) + "<br/>";
                            }
                            if (
                              signupDiscountSuppliers.includes(
                                product.source_name
                              )
                            ) {
                              message +=
                                "To receive the lowest price, this supplier requires you to sign up to their website.";
                            }
                            if (
                              OOSbyZIPSuppliers.includes(
                                product.source_name
                              )
                            ) {
                              message +=
                                "\nIf the product is out of stock - Change the ZIP CODE!\n";
                            }
                            if (message !== "" && (localStorage.getItem("showSuppMesssage" + product.source_name) === 'true' || localStorage.getItem("showSuppMesssage" + product.source_name) === null)) {
                              setShowMessageObj({
                                message: message,
                                messageAction: openSuppLink,
                                messageActionArg: product,
                                source: product.source_name
                              });
                            } else {
                              openSuppLink(product);
                            }
                          }}
                        />
                        {(product.source_name === "boscovs" || product.source_name === "dickssportinggoods" || product.source_name === "rei" || product.source_name === "b2bsportswear" || product.source_name === "joann") &&
                          <div className="warningSign">
                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                            <span className="warText"> See the correct variation on {product.source_name}'s website.</span>
                          </div>}
                      </div>
                      {product.title.includes("Last Update") && (
                        <span
                          style={{
                            fontSize: "0.7vw",
                            position: "absolute",
                            marginTop: "5.75%",
                            marginLeft: "-1%",
                            opacity: "0.7",
                            height: "0px",
                          }}
                          id="foundOnText"
                        >
                          Found on{" "}
                          {new Date(product.title.split("Last Update: ")[1])
                            .toLocaleString()
                            .includes("Invalid")
                            ? product.title.split("Last Update: ")[1]
                            : new Date(
                              product.title.split("Last Update: ")[1]
                            ).toLocaleString()}
                        </span>
                      )}
                    </div>
                  </td>
                  <td style={{ width: "8vw", maxWidth: "8vw", color: !categorySalesRankCount[product.category] && "darkred" }}>
                    {product.category === null ? "N/A" : product.category}
                  </td>
                  <td id="bsrTt" style={{ minWidth: "5.6vw", color: !categorySalesRankCount[product.category] && "darkred" }}>
                    #{product.sales_rank}{categorySalesRankCount[product.category] && <><br /><span style={{ fontSize: "83%" }}>Top {((product.sales_rank / categorySalesRankCount[product.category]) * 100) < 0.01 ? "<0.01" : ((product.sales_rank / categorySalesRankCount[product.category]) * 100).toFixed(((product.sales_rank / categorySalesRankCount[product.category]) * 100) < 0.1 ? 2 : 1)}%</span></>}
                  </td>
                  <td>
                    <div style={{ marginTop: "23%", width: "4vw", maxWidth: "4vw" }} />
                    <a
                      href={product.asin}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline", color: "#8B3C7E" }}
                    >
                      {
                        product.asin.split("/")[
                        product.asin.split("/").length - 1
                        ]
                      }
                    </a>
                    <br />
                    <img
                      className="graphicon"
                      alt="ebay"
                      onClick={() =>
                        window.open(product.upc !== null ?
                          `https://www.ebay.com/sch/i.html?&_nkw=%28${product.upc}%29&_odkw=${product.upc}` :
                          `https://www.ebay.com/sch/i.html?&_nkw=${product.title.split(" | At")[0].slice(0, 40)}&_odkw=${product.title.split(" | At")[0].slice(0, 40)}`,
                          "_blank"
                        )
                      }
                      src={"/assets/ebay.png"}
                      style={{
                        width: "2.75vw",
                        marginTop: "5px",
                        height: "calc(1.2vh + .75vw)",
                        borderRadius: "20px",
                        border: "2px solid gray",
                        padding: "3px",
                        objectFit: "contain"
                      }}
                    />
                  </td>
                  <td style={{ width: "2.5vw", maxWidth: "2.5vw" }}>x{product.moq}</td>
                  <td style={{ width: "6vw", maxWidth: "6vw" }} id="buyPriceTt">
                    ${product.price.toFixed(2)}
                  </td>
                  <td
                    className="no-bg"
                    id="dyamicKeepaTt"
                    style={{
                      width: "18vw",
                      maxWidth: "18vw",
                      zIndex: "9999 !important",
                      padding: "0"
                    }}
                  >
                    <img
                      loading="lazy"
                      onClick={() =>
                        setDKGAsin(
                          product.asin.split("/")[
                          product.asin.split("/").length - 1
                          ]
                        )
                      }
                      src={DKGasin === null ? `https://graph.keepa.com/?asin=${product.asin.split("/")[
                        product.asin.split("/").length - 1
                        ]
                        }&domain=com&salesrank=${+bsr_state}&amazon=${+az_state}&new=${+new_state}&bb=${+bb_state}&fba=${+fba_state}&range=${range_state}` : '/assets/dkg_loading.png'}
                      alt="keepahist"
                      style={{ position: "relative", border: "1px solid gray" }}
                      className="keepagraphoos"
                    />
                  </td>
                  <td className="no-bg" style={{ width: "15vw" }}>
                    <div
                      className="columns is-centered"
                      style={{ display: "flex" }}
                    >
                      <button
                        className="button is-rounded"
                        id="hideTt"
                        style={{
                          backgroundColor: "#8B3C7E",
                          color: "white",
                          fontWeight: "700",
                          marginLeft: "3px",
                          fontSize: ".64vw",
                        }}
                        onClick={() => {
                          setHideModalProduct(product);
                        }}
                      >
                        HIDE FROM OTHERS
                      </button>
                      <FavoritesIcon oos={true} url={product.url} favorite={product.favorite} />
                    </div>
                    <div
                      className="columns is-centered"
                      style={{ display: "flex", marginTop: "1px" }}
                    >
                      <img
                        className="graphicon advDataTt"
                        alt="graph"
                        onClick={() => setAdvancedDataProduct(product)}
                        src={"/assets/graph.png"}
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "3px solid purple",
                          boxShadow: "0px 0px 5px 1px purple",
                          padding: "3px",
                        }}
                      />
                      <i
                        className="fa fa-unlock-alt graphicon gatedTt"
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          padding: "0.3vw 0.2vw",
                          cursor: "pointer",
                          fontSize: "1.6vw",
                          color: gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? "white" : "rgb(195, 65, 2)",
                          backgroundColor: gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions.length === 0 ? "green" : (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions[0].reasons[0].reasonCode === "APPROVAL_REQUIRED" ? "orange" : "red")) : "white"
                        }}
                        id="gatedTt"
                        onClick={() => {
                          if (props.sellerId === "none" || props.sellerId === "") {
                            setLoginWAZ(true);
                          }
                          window
                            .open(
                              "https://sellercentral.amazon.com/product-search/search?q=" +
                              product.asin.split("/")[
                              product.asin.split("/").length - 1
                              ],
                              "_blank"
                            )
                            .focus()
                        }}
                        aria-hidden="true"
                      ></i>
                      <img
                        className="graphicon keepaTt"
                        alt="keepa"
                        onClick={() =>
                          window.open(
                            `https://keepa.com/#!product/1-${product.asin.split("/")[
                            product.asin.split("/").length - 1
                            ]
                            }}`,
                            "_blank"
                          )
                        }
                        src={"/assets/keepa.png"}
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          padding: "3px",
                        }}
                      />
                      <i
                        className="fa fa-exclamation-triangle graphicon tooltip"
                        style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          padding: "0.4vw 0.2vw",
                          cursor: "pointer",
                          fontSize: "1.3vw",
                          color: "darkred",
                        }}
                        id="reportTt"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Report a mismatch?\nNote: Users who find 50 mismatches will receive a free subscription!\n\nThe report will be sent to our team for verification. After confirming the mismatch, it will be counted."
                            )
                          ) {
                            props.reportMismatch(
                              product.asin.split("/")[
                              product.asin.split("/").length - 1
                              ]
                            );
                          }
                        }}
                        aria-hidden="true"
                      >
                        {" "}
                        <span
                          class="tooltiptext"
                          style={{
                            zIndex: "2000",
                            fontFamily: "sans-serif",
                            marginTop: "-25%",
                            fontSize: ".75vw",
                          }}
                        >
                          Report a mismatch
                        </span>
                      </i>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div
          style={{
            width: "70vw",
            margin: "auto",
            height: "10vh",
            textAlign: "center",
          }}
        >
          <button
            className="button"
            style={{
              float: "left",
              width: "10vw",
              fontSize: "1.1vw",
              backgroundColor: "#8B3C7E",
              color: "white",
            }}
            onClick={() => {
              props.setPage((page) => (page > 1 ? page - 1 : 1));
              window.scrollTo(0, 0);
            }}
          >
            Previous Page
          </button>
          <span>Page {props.page}</span>
          <button
            className="button"
            style={{
              float: "right",
              width: "10vw",
              fontSize: "1.1vw",
              backgroundColor: "#8B3C7E",
              color: "white",
            }}
            onClick={() => {
              props.setPage((page) => page + 1);
              window.scrollTo(0, 0);
            }}
          >
            Next page
          </button>
        </div>
      </div>
      {hideModalProduct !== "" && (
        <ModalForHide
          isMobile={props.isMobile}
          setHideModalProduct={setHideModalProduct}
          hideModalProduct={hideModalProduct}
          hideCredits={props.hideCredits}
        />
      )}
      {linkUrl !== "" && (
        <NotDirectLinkPopup
          isMobile={props.isMobile}
          setLinkUrl={setLinkUrl}
          linkUrl={linkUrl}
        />
      )}
      {DKGasin !== null && (
        <DynamicKeepaGraphPopup
          asin={DKGasin}
          setDKGAsin={setDKGAsin}
          bsr_state={bsr_state}
          setBsr={setBsr}
          bb_state={bb_state}
          setBb={setBb}
          az_state={az_state}
          setAz={setAz}
          fba_state={fba_state}
          setFba={setFba}
          new_state={new_state}
          setNew={setNew}
          range_state={range_state}
          setRange={setRange} />
      )}
      {loginWAZ && localStorage.getItem("no_azlog_popup") !== "1" && (
        <div
          id="modal-js-example"
          className={
            "modal" + (loginWAZ !== null ? " is-active" : "")
          }
        >
          <div className="modal-background"></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "38vw" }}
          >
            <div className="box" style={{ textAlign: "center", fontWeight: "600" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Log in to your Amazon account to automate the eligibility check!
              </p>
              <br />
              <i
                className="fa fa-unlock-alt graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "green"
                }} />&emsp;GREEN - You CAN sell this item!
              <br /><br />
              <i
                className="fa fa-unlock-alt graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "red"
                }} />&emsp;RED - You CANNOT sell this item!
              <br /><br />
              <i
                className="fa fa-unlock-alt graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "orange"
                }} />&emsp;ORANGE - You need to APPLY to sell this item!
              <br /><br /><br />
              <i class="fa fa-arrow-right" aria-hidden="true" style={{ fontSize: "25px", marginRight: "10px" }}></i>
              {user && user.email && <img src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png" onClick={() => window.open('https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.5e50b4d6-1a6f-46fa-a523-7dc2bd0266a8&version=beta&state=' + user.email)} style={{ resizeMode: 'center', cursor: "pointer", boxShadow: "0px 0px 5px 0px gray", borderRadius: "5px", marginBottom: "-8px" }} />}
              <i class="fa fa-arrow-left" aria-hidden="true" style={{ fontSize: "25px", marginLeft: "10px" }}></i>
              <br /><br /><br />
              <div className="columns is-mobile is-centered" style={{ marginBottom: "1%", marginTop: '1%' }}>
                <button
                  className="button is-rounded is-danger is-outlined"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setLoginWAZ(false);
                    localStorage.setItem("no_azlog_popup", "1")
                  }}
                >
                  Do not show this message again
                </button>&emsp;
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setLoginWAZ(false);
                  }}
                >
                  Close for Now
                </button>
              </div>
            </div>
          </div>
        </div>)}
      {advancedDataProduct !== null &&
        <OOSAdvancedDataModal
          advancedDataProduct={advancedDataProduct}
          setAdvancedDataProduct={setAdvancedDataProduct}
          isMobile={props.isMobile}
        />
      }
      {showMessageObj !== null && (
        <MessageBeforeLinkPopup
          showMessageObj={showMessageObj}
          setShowMessageObj={setShowMessageObj}
        />
      )}
    </>
  );
}
