import React, { useEffect, useState } from "react";
import "../Pricing.css";
import ListItem from "./ListItem";
import SubscribeButtonTra from "./SubscribeButtonTra";

function PriceCard(props) {
  return (
    <div className="column" style={{ marginBottom: "5vh" }}>
      <div className="card is-3 priceCard">
        <div className="columns is-mobile mb-0" style={{ height: "10vh" }}>
          <div className="column is-8" style={{ paddingBottom: "0" }}>
            <strong className="textCard">{props.plan.name === "GA" ? <span style={{ fontSize: props.isMobile ? "40%" : "55%" }}>MEET WİTH NEPETO & ABRA</span> : props.plan.name}</strong>
          </div>

          <div className="column is-4" style={{ paddingBottom: "0" }}>
            <img
              src={props.plan.img}
              alt="Card"
              style={{ height: "100%", padding: "10%" }}
            />
          </div>
        </div>

        <div
          style={{
            marginTop: "-3vh",
            fontSize: "380%",
            fontWeight: "600",
            textAlign: "center",
            color: "rgb(140, 62, 124)",
            lineHeight: props.plan.isEnterprise ? "70%" : "100%",
          }}
        >
          $
          {props.plan.isEnterprise ? "0" : Math.ceil((props.isMonthly
            ? props.plan.price_monthly
            : props.plan.price_annually))}
          {!props.plan.isEnterprise ? (
            <span style={{ fontSize: "50%" }}>/mo</span>
          ) : <><br /><span style={{ fontSize: "50%", lineHeight: "30%" }}>First 2 weeks free!<br />Then ${Math.ceil((props.isMonthly
            ? (props.plan.price_monthly)
            : (props.plan.price_annually)))}/month.</span><br /><br /></>}
        </div>

        {!props.noDiscount && (!props.isMonthly && (
          <><p className="additext" style={{ fontWeight: "700", fontSize: props.isMobile ? "100%" : "1.7vh", textAlign: "center" }}>
            *billed annually
          </p>
          </>
        ))}

        <div class="card-content" style={{ marginTop: "-1.5vh", height: props.higherCard ? props.isMobile ? "100vh" : "70vh" : (props.plan.isEnterprise ? "44.7vh" : "55vh") }}>
          <div>
            {props.plan.checkList.map((item) => {
              return <ListItem sent={item}></ListItem>;
            })}
            {localStorage.getItem('affiliate') === "ga" && <ListItem sent={"Nepeto's expert 1-on-1 welcome call (60 min) - Let's talk about Amazon and the right business mindset"}></ListItem>}
          </div>
        </div>
        {!props.isMonthly && <p className="additext" style={{ fontWeight: "700", fontSize: props.isMobile ? "100%" : "1.4vh", textAlign: "center" }}>
          *billed annually
        </p>}
        <footer
          class="card-footer"
          style={{ borderTop: "none", paddingBottom: "1vh" }}
        >
          <SubscribeButtonTra
            plan={props.plan.name}
            plan_obj={props.plan}
            plan_id={props.isMonthly ? (props.activePlanLevel === 0 ? props.plan.plan_id_monthly : props.plan.plan_id_monthly_no_trial) : (props.activePlanLevel === 0 ? props.plan.plan_id_annually : props.plan.plan_id_annually_no_trial)}
            planLevel={props.plan.planLevel}
            monthly={props.isMonthly}
            activePlanLevel={props.activePlanLevel}
            price={Math.ceil((props.isMonthly ? props.plan.price_monthly : props.plan.price_annually))}
            isMobile={props.isMobile}
          />
        </footer>
      </div>
    </div>
  );
}

export default PriceCard;
