import { useState } from "react";

function ModalForFavorites(props) {
  const [tagFilter, setTagFilter] = useState("");

  return (
    <>
      <div
        id="modal-js-example"
        className={"modal" + (props.showFavoriteProducts ? " is-active" : "")}
      >
        <div className="modal-background" onClick={() => {props.setShowFavoriteProducts(false)}}></div>

        <div
          className="modal-content"
          style={{ width: props.isMobile ? "90vw" : "auto", minWidth: "25vw" }}
        >
          <div className="box" style={{ textAlign: "center" }}>
            <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
              Favorite Products
            </p>
            <br />
            {props.products.length === 0 ? 
              <span style={{ color: "#8B3C7E" }}>
                Here you will find all the products<br/>
                you've added to your favorites.</span> :
              <table class="table is-bordered" style={{ margin: "auto" }}>
                <thead>
                  <tr>
                    <th style={{ color: "#8B3C7E" }}>Product</th>
                    <th style={{ color: "#8B3C7E" }}>ASIN</th>
                    <th style={{ color: "#8B3C7E" }}>Tag - Just Type<br/><input
                            style={{
                              width: "80%",
                              color: "#8B3C7E",
                              fontWeight: "600"
                            }}
                            placeholder="Filter By Tag"
                            onChange={(e) => setTagFilter(e.target.value)}
                          /></th>
                    <th style={{ color: "#8B3C7E" }}>X</th>
                  </tr>
                </thead>
                <tbody>
                  {props.products.filter((product) => (localStorage.getItem(product["url"].slice(-9) + product['asin'].split("/").pop() + "TAG") ?? "").includes(tagFilter)).map((product, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <img
                            alt="product_image"
                            loading="lazy"
                            src={product["img"]}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/assets/noimage.png";
                            }}
                            style={{
                              height: props.isMobile ? "15vw" : "4.5vw",
                              width: props.isMobile ? "15vw" : "4.5vw",
                              objectFit: "scale-down",
                              cursor: "pointer",
                              borderRadius: "8vw",
                              border: "5px solid #E3D0DF",
                            }}
                            onClick={() => {
                              window.open(product["url"]);
                            }}
                            className="bigHover"
                          />
                        </td>
                        <td
                          style={{
                            color: "#8B3C7E",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          <a
                            href={product.asin}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              textDecoration: "underline",
                              color: "#8B3C7E",
                            }}
                          >
                            {product["asin"].split("/").pop()}
                          </a>
                        </td>
                        <td
                          style={{
                            color: "#8B3C7E",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          <input
                            style={{
                              width: "80%",
                              color: "#8B3C7E",
                              fontWeight: "600"
                            }}
                            key={tagFilter}
                            placeholder={localStorage.getItem(product["url"].slice(-9) + product['asin'].split("/").pop() + "TAG") ?? "NO TAG"}
                            onChange={(e) => localStorage.setItem(product["url"].slice(-9) + product['asin'].split("/").pop() + "TAG", e.target.value)}
                          />
                        </td>
                        <td style={{
                            color: "#8B3C7E",
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}>
                          <i class="fa fa-times-circle-o" aria-hidden="true" style={{fontSize: "160%", cursor: "pointer"}} onClick={() => {
                            props.setProducts(props.products.filter((product, innerindex) => innerindex !== index));
                            localStorage.setItem(product["url"].slice(-9) + product['asin'].split("/").pop() + "FAVHIDE", 1)
                          }}></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            }
            <br />
            <br />
            <div className="columns is-mobile is-centered">
              <button
                className="button is-rounded is-danger"
                style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                onClick={() => {
                  props.setShowFavoriteProducts(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalForFavorites;
