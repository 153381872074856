import { useAuth0 } from "@auth0/auth0-react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useState } from "react";

const initialOptions = {
  clientId: "AXOglQsiyPeBp1GYe2Ehys3IeS7Od9AngwurkI54YnGXQuWYY9Pyl5aA2GwCpW7Oz1wRRF5JVWxTAh5Y",
  vault: true,
  intent: "subscription",
};

function SubscribeButtonPayPal(props) {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const [loadingRedirect, setLoadingRedirect] = useState(false);

  const buySubscription = async (subscription_id) => {
    setLoadingRedirect(true);
    const accessToken = await getAccessTokenSilently();
    fetch(
      `https://server.nepeto.com/subscribed/${props.plan}/${props.monthly}/${subscription_id}/${props.price}/`,
      {
        mode: "cors",
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data;
        setLoadingRedirect(false);
      })
      .catch(() => {
        alert("There has been an error. Please contact our support, and they will help you ASAP.");
        setLoadingRedirect(false);
      });
  };

  if (!isLoading && isAuthenticated) {
    return (
      <div style={{ margin: "auto" }}>
        {props.activePlanLevel !== undefined && <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            style={{
              shape: "rect",
              layout: "vertical",
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                "plan_id": props.plan_id,
                application_context: {
                  shipping_preference: "NO_SHIPPING"
                }
              });
            }}
            onApprove={(data) => {
              buySubscription(data.subscriptionID)
            }}
            onCancel={() => ""}
            onError={() => console.log("Please try again.")} />
        </PayPalScriptProvider>}

        {(loadingRedirect || props.activePlanLevel === undefined) && <div style={{
          position: 'absolute',
          zIndex: 2147483647,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          transform: 'translate3d(0, 0, 0)',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          background: 'radial-gradient(50% 50%, ellipse closest-corner, rgba(0, 0, 0, 0.6) 1%, rgba(0, 0, 0, 0.8) 100%)',
          color: '#fff',
          textAlign: "center",
          lineHeight: "80vh"
        }}>Loading...</div>}
      </div>
    );
  } else {
    return (
      <button
        className="button is-rounded is-size-6-mobile is-size-6-tablet"
        style={{ backgroundColor: "#32CD32", margin: "auto", color: "#ffffff" }}
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: "signup",
            },
            appState: { returnTo: "/pricing?s=1" },
          })
        }
      >
        Sign up for Free!
      </button>
    );
  }
}

export default SubscribeButtonPayPal;
