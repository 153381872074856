import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import CardSwipper from "../Products/Mobile/CardSwipper";
import ScannerProductsTable from "./ScannerProductsTable.js";
import ScannerFilterBar from "./ScannerFilterBar.js";
import Papa from 'papaparse';
import * as XLSX from 'xlsx';

function Scanner(props) {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [fileName, setFileName] = useState(null);
  const [identifier, setIdentifier] = useState("");
  const [filters, setFilters] = useState({});
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [sortASC, setSortASC] = useState(false);
  const [vlookupObj, setVlookupObj] = useState({});
  const CATEGORY_BSR_COUNT = {
    'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515,
    'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843,
    'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162,
    'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647,
    'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328,
    'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340,
    'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048,
    'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515,
    'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789,
    'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893,
    'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107,
    'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894,
    "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127,
    "Collectible Coins": 210648, "Camera & Photo": 4021127
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    function calculateROI(item, settings) {
      let logisticsCost = parseFloat(localStorage.getItem("costPrep") || 0);
      let price = item.price * (1 - ((settings.priceCoupon || 0) / 100));
      let azPrice = item.az_price;
      let fbaCost = settings.isFBM ? 0 : item.fba_fee;
      let sellingOnAzFees = item.ref_fee_perc * item.az_price;

      return (azPrice - price - fbaCost - logisticsCost - sellingOnAzFees) / (price + logisticsCost);
    }

    function filterData(data, filters) {
      return data.filter(item => {
        if (parseInt(filters.noVariations || "0") !== 0 && item.variations !== "null") {
          return false;
        }

        // amazonNotSeller
        if (parseInt(filters.amazonNotSeller || "0") !== 0) {
          if (item.az_sells_it) {
            return false;
          }
        }

        // top_bsr
        if (filters.top_bsr && (filters.top_bsr[0] !== -1 || filters.top_bsr[1] !== -1)) {
          let categoryCount = CATEGORY_BSR_COUNT[item.category] || 10000000;
          let salesRankPercentage = (item.sales_rank / categoryCount) * 100;

          if (filters.top_bsr[0] !== -1 && salesRankPercentage < filters.top_bsr[0]) {
            return false;
          }

          if (filters.top_bsr[1] !== -1 && salesRankPercentage > filters.top_bsr[1]) {
            return false;
          }
        }

        // profit
        if (filters.profit && (filters.profit[0] !== -1 || filters.profit[1] !== -1)) {
          let profitValue = item.az_price - (item.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : item.fba_fee) - (item.ref_fee_perc * item.az_price) - parseFloat(localStorage.getItem("costPrep") || 0);

          if (filters.profit[0] !== -1 && profitValue < filters.profit[0]) {
            return false;
          }

          if (filters.profit[1] !== -1 && profitValue > filters.profit[1]) {
            return false;
          }
        }

        // vlookup
        if (filters.vlookup && filters.vlookup !== "") {
          let vlookup = filters.vlookup;
          let idString = '';
          const priceDict = {};
          if (vlookup[0].hasOwnProperty("ASIN")) {
            vlookup.forEach(product => {
              idString += product.ASIN + ',';
              priceDict[product.ASIN] = {"price": product.Price, "source": product?.Source ?? ""};
            });
            if (!idString.includes(item.asin)) {
              return false
            }
          } else if(vlookup[0].hasOwnProperty("Barcode")) {
            vlookup.forEach(product => {
              idString += product.Barcode.replaceAll('.', '').replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "") + ',';
              priceDict[product.Barcode.replaceAll('.', '').replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "")] = {"price": product.Price, "source": product?.Source ?? ""};
            });
            if (!item.upcs.split(',').some(upc => idString.includes(upc.replaceAll('.', '').replaceAll('0', '').replaceAll(" ", "").replaceAll("'", "").replaceAll("-", "")))) {
              return false
            }
          }
          setVlookupObj(priceDict);
        }

        // search
        if (filters.search && filters.search !== "") {
          let searchLower = filters.search.toLowerCase();
          if (!item.title.toLowerCase().includes(searchLower) && !item.asin.includes(filters.search)) {
            return false;
          }
        }

        // category
        if (filters.category && filters.category !== "Category" && !filters.category.includes(item.category)) {
          return false;
        }

        // qty, moq, bsr
        for (let filterAttr of ["qty", "moq", "bsr"]) {
          if (filters[filterAttr] && (filters[filterAttr][0] !== -1 || filters[filterAttr][1] !== -1)) {
            let value = item[filterAttr === "bsr" ? "sales_rank" : filterAttr];

            if (filters[filterAttr][0] !== -1 && value < filters[filterAttr][0]) {
              return false;
            }

            if (filters[filterAttr][1] !== -1 && value > filters[filterAttr][1]) {
              return false;
            }
          }
        }

        // price
        if (filters.price && (filters.price[0] !== -1 || filters.price[1] !== -1)) {
          let price = item.price * (1 - ((filters.priceCoupon || 0) / 100));

          if (filters.price[0] !== -1 && price < filters.price[0]) {
            return false;
          }

          if (filters.price[1] !== -1 && price > filters.price[1]) {
            return false;
          }
        }

        // roi
        if (filters.roi && (filters.roi[0] !== -1 || filters.roi[1] !== -1)) {
          let roi = calculateROI(item, filters);

          if (filters.roi[0] !== -1 && roi < (filters.roi[0] / 100)) {
            return false;
          }

          if (filters.roi[1] !== -1 && roi > (filters.roi[1] / 100)) {
            return false;
          }
        }

        // offers
        if (filters.offers && (filters.offers[0] !== -1 || filters.offers[1] !== -1)) {
          let offersCount = item.total_offers_count;

          if (filters.offers[0] !== -1 && offersCount < filters.offers[0]) {
            return false;
          }

          if (filters.offers[1] !== -1 && offersCount > filters.offers[1]) {
            return false;
          }
        }

        // offers
        if (filters.sales && (filters.sales[0] !== -1 || filters.sales[1] !== -1)) {
          let sales = item.keepa_monthly_sold > 0 ? item.keepa_monthly_sold : item.estimate_sales_30_days;

          if (filters.sales[0] !== -1 && sales < filters.sales[0]) {
            return false;
          }

          if (filters.sales[1] !== -1 && sales > filters.sales[1]) {
            return false;
          }
        }

        // offers
        if (filters.bsr_drops_30_days && (filters.bsr_drops_30_days[0] !== -1 || filters.bsr_drops_30_days[1] !== -1)) {
          let drops = item.sales_rank_drops_30_days;

          if (filters.bsr_drops_30_days[0] !== -1 && drops < filters.bsr_drops_30_days[0]) {
            return false;
          }

          if (filters.bsr_drops_30_days[1] !== -1 && drops > filters.bsr_drops_30_days[1]) {
            return false;
          }
        }

        return true;
      });
    }

    const sortFunction = (a, b) => {
      if (filters.sortBy) {
        let getKey = (n, key) => {
          if (filters.sortBy[0] === "roi") {
            return parseFloat(calculateROI(n, filters))
          }
          if (filters.sortBy[0] === "profit") {
            return parseFloat(n.az_price - (n.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : n.fba_fee) - (n.ref_fee_perc * n.az_price) - parseFloat(localStorage.getItem("costPrep") || 0))
          }
          return parseFloat(n[key])
        }
        if (filters.sortBy[1]) {
          return getKey(a, filters.sortBy[0]) - getKey(b, filters.sortBy[0])
        } else {
          return getKey(b, filters.sortBy[0]) - getKey(a, filters.sortBy[0])
        }
      } else {
        return 1
      }
    }

    setFilteredData(filterData(data, filters).sort((a, b) => sortFunction(a, b)));
  }, [filters, data])

  async function uploadCSV(file) {
    if (props.activePlanLevel !== "Master Plan") {
      alert("You must be a Master plan subscriber to use the Nepeto CSV Scanner!")
    } else {
      const lastClickTimestamp = localStorage.getItem('lastClickTimestamp') || -1;
      const minutesSinceLastClick = (Date.now() - parseInt(lastClickTimestamp)) / (1000 * 60);
      if (minutesSinceLastClick < 2 && lastClickTimestamp !== -1) {
        alert(`Please wait ${((2 - minutesSinceLastClick) * 60).toFixed(0)} seconds before scanning again!`);
        window.location.reload();
        return
      }
      localStorage.setItem('lastClickTimestamp', Date.now());
      setFileName("");
      if (file.size > 10000000) {
        alert(
          "Your file is larger than 10MB. Please consider splitting the Excel file and sending multiple scan requests."
        );
      } else {
        if (file) {
          const reader = new FileReader();

          reader.onload = function (e) {
            const fileName = file.name.toLowerCase();
            if (fileName.endsWith('.csv')) {
              const text = e.target.result;
              Papa.parse(text, {
                header: true,
                complete: (results) => {
                  sendFile(results.data);
                },
              });
            } else if (fileName.endsWith('.xlsx')) {
              const data = new Uint8Array(e.target.result);
              const workbook = XLSX.read(data, { type: 'array' });
              const firstSheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[firstSheetName];
              const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
              const headers = json[0];
              const dataRows = json.slice(1).map((row) => {
                const obj = {};
                headers.forEach((header, index) => {
                  obj[header] = row[index];
                });
                return obj;
              });
              sendFile(dataRows);
            }
          };

          if (file.name.toLowerCase().endsWith('.csv')) {
            reader.readAsText(file);
          } else if (file.name.toLowerCase().endsWith('.xlsx')) {
            reader.readAsArrayBuffer(file);
          }
        }
      }
    }

    const sendFile = async (data_l) => {
      // Process data_l in batches with delay
      const batchSize = 10;
      let index = 0;

      async function processBatch() {
        if (index < data_l.length) {
          setIsProductsLoading(true);
          const batch = data_l.slice(index, index + batchSize).map(item => ({
            [identifier]: item[[identifier]].split(".")[0],
            "Price": item["Price"] ? item["Price"].replace("$", "") : -1,
            "Name": item["Name"],
            "Image": item["Image"]
          }));;

          const accessToken = await getAccessTokenSilently();
          const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: {
              Accept: "application/json; charset=utf8",
              "Content-Type": "application/json; charset=utf8",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              csv_file: batch,
              identifier: identifier
            }),
          };

          try {
            fetch("https://server.nepeto.com/upload_scanner_csv/", requestOptions)
              .then((response) => response.json())
              .then((data) => {
                if (data === "No permissions") {
                  navigate("/pricing")
                } else {
                  if (data.length > 0 && !props.isMobile) {
                    let filteredData = data
                    if (filteredData.length > 0) {
                      let filteredDataWithHide = filteredData.filter(prod => (localStorage.getItem(prod["asin"].split("/")[prod["asin"].split("/").length - 1] + "NIU") === null || (new Date() > localStorage.getItem(prod["asin"].split("/")[prod["asin"].split("/").length - 1] + "NIU"))))
                      if (filteredDataWithHide.length > 0) {
                        setData((prevData) => [...prevData, ...filteredDataWithHide]);
                        if (index >= data_l.length) {
                          setIsProductsLoading(false);
                        }
                      }
                    }
                  } else {
                    setData((prevData) => [...prevData, ...data])
                    if (index >= data_l.length) {
                      setIsProductsLoading(false);
                    }
                  }
                }
              })
              .catch((e) => {
                alert("Error! Try again or contact us please.");
              });
          } catch (e) {
            alert("Error! Try again or contact us please.");
          }

          index += batchSize;

          // Set timeout for 20 second before processing next batch
          setTimeout(processBatch, 20000); // 20000 milliseconds = 20 second
        } else {
          setIsProductsLoading(false);
        }
      }

      // Start processing batches
      processBatch();
    }
  }

  return (
    <>
      <Helmet>
        <title>Nepeto - CSV Scanner</title>
        <meta
          name="description"
          content="CSV Scanner! Upload your CSV file containing products you want to find matches and search for profitable products from there to resell on Amazon."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, scanner, csv, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div style={{ marginBottom: "3.5vh" }}>
        <h1
          style={{
            fontSize: props.isMobile ? "3vh" : "2vw",
            fontWeight: "380",
            textAlign: "center",
            color: "#8c3e7c",
            marginBottom: props.isMobile && "2vh",
          }}
        >
          CSV Scanner
        </h1>
        <h2
          style={{
            fontSize: props.isMobile ? "1.5vh" : "1vw",
            fontWeight: "380",
            textAlign: "center",
            color: "#8c3e7c",
            marginBottom: props.isMobile && "2vh",
          }}
        >
          Your supplier sent you a CSV with their products, and you want to
          decide what to purchase from them.
          <br />
          Doing it manually will take hours, converting each UPC to ASIN and
          searching for profits. The CSV Scanner will automate this process.
        </h2>
        <h2
          style={{
            fontSize: props.isMobile ? "1.5vh" : "1vw",
            fontWeight: "380",
            textAlign: "center",
            color: "rgb(230,50,50)",
            marginBottom: props.isMobile && "2vh",
          }}
        >
          The file must contain: Identifier Code (UPC/EAN/ASIN), Price, Name (Optional), Image (Optional)
          <br /><span style={{ fontSize: "75%" }}>* Optional: providing these will generate more accurate results.</span>
          {identifier === "Barcode" && <span style={{ fontSize: "95%", color: "green" }}><br />* Note: Matching by UPC/EAN will return all possible matches for you to choose the best one!</span>}
        </h2>

        {data.length === 0 && (identifier === "" ?
          <div style={{ width: "50vw", margin: "auto", alignItems: "center", textAlign: "center", backgroundColor: "rgba(200,200,200,0.2)", borderRadius: "30px", marginTop: "1vh", padding: "2vh 1vw 7vh 1vw" }}>
            <h2 style={{ fontSize: "150%", marginBottom: "2vh" }}>Select the identifier for product matching</h2>
            <div style={{ display: "flex", flexDirection: "row", verticalAlign: "middle", margin: "auto" }}>
              <div onClick={() => setIdentifier("ASIN")} style={{ cursor: "pointer", boxShadow: "0px 0px 5px 0px black", width: "10vw", margin: "auto", alignItems: "center", textAlign: "center", display: "grid", fontSize: "175%", backgroundColor: "#E3D0DF", borderRadius: "30px", height: "16vh" }}>
                ASIN
              </div>
              <div onClick={() => setIdentifier("Barcode")} style={{ cursor: "pointer", boxShadow: "0px 0px 5px 0px black", width: "10vw", margin: "auto", alignItems: "center", textAlign: "center", display: "grid", fontSize: "150%", backgroundColor: "#E3D0DF", borderRadius: "30px", height: "16vh" }}>
                UPC/<br />EAN
              </div>
              <div style={{ width: "10vw", margin: "auto", alignItems: "center", textAlign: "center", display: "grid", fontSize: "150%", backgroundColor: "lightgray", borderRadius: "30px", height: "16vh" }}>
                Name<br />*Soon*
              </div>
            </div>
          </div> :
          <div style={{ width: "50vw", margin: "auto", alignItems: "center", textAlign: "center", backgroundColor: "rgba(200,200,200,0.2)", borderRadius: "30px", marginTop: "1vh", padding: "3vh 1vw" }}>
            <h2 style={{ fontSize: "150%", marginBottom: "2vh" }}>Upload the CSV file<br />Make sure you use our template or use the same column names!<br /><span onClick={() => window.location.href = `data:text/csv;charset=utf-8,${identifier},Price,Name,Image\n`} style={{ color: "slateblue", textDecoration: "underline", cursor: "pointer" }}>Click here to download the template</span></h2>
            <div
              class="file has-name is-link is-boxed is-centered"
              style={{ backgroundColor: "white", width: "10vw", margin: "auto" }}
            >
              <label class="file-label">
                <input
                  class="file-input"
                  accept=".csv"
                  type="file"
                  name="resume"
                  onChange={(e) => {
                    uploadCSV(e.target.files[0]);
                  }}
                />
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fa fa-upload" aria-hidden="true"></i>
                  </span>
                  <span class="file-label">
                    Choose a file…
                    <br />
                    <strong style={{ fontSize: "60%", color: "lightgray" }}>
                      Accepts .csv .xlsx files
                    </strong>
                  </span>
                </span>
                <span class="file-name">
                  {fileName !== null ? (
                    fileName === "" ? (
                      <progress
                        style={{ marginTop: "6px", width: "50%" }}
                        class="progress is-link is-small"
                        max="100"
                      />
                    ) : (
                      fileName
                    )
                  ) : (
                    "No file uploaded"
                  )}
                </span>
              </label>
            </div></div>)}
        <br />
        <div style={{ marginBottom: "30vh", display: "block" }}>
          {data.length > 0 && <>
            {(!props.isMobile && <ScannerFilterBar data={data} setFilters={setFilters} filters={filters} activePlanLevel={props.activePlanLevel} />)}

            {(props.isMobile ? (
              <CardSwipper data={filteredData} hideCredits={props.hideCredits} isMobile={props.isMobile} />
            ) : <ScannerProductsTable
              data={filteredData}
              setData={setData}
              isMobile={props.isMobile}
              supplierType={"storefront"}
              hideCredits={props.hideCredits}
              showAdv={true}
              isProductsLoading={isProductsLoading}
              filters={filters}
              setSortASC={setSortASC}
              sortASC={sortASC}
              setFilters={setFilters}
              identifier={identifier}
              sellerId={props.sellerId}
              refreshToken={props.refreshToken}
              vlookupObj={vlookupObj}
            />)
            }</>}
        </div>
        {isProductsLoading &&
          <span style={{ textAlign: "center", margin: "auto", display: "block" }}>Loading products...&emsp;<i
            className="fa fa-spinner fa-spin"
            aria-hidden="true"
            style={{ fontSize: "100%" }}
          ></i></span>}
      </div>
    </>
  );
}

export default Scanner;
