import React, { useEffect } from "react";
import "./Pricing.css";
import PriceCard from "./components/PriceCard";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import CancelSubModal from "../../components/CancelSubModal";

function Pricing(props) {
  const { isAuthenticated, isLoading } = useAuth0();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const prices = urlParams.has("raExplorer") ? [{
    name: "Starter",
    price_monthly: 17,
    price_annually: 14,
    img: "/assets/starterFlower.png",
    checkList: [
      "RA Store Explorer - It Will Find What to Scan In-Store for You!",
      "Retail Arbitrage Mobile App",
      "Online Arbitrage Chrome Extension",
      "Facebook Community & Discord Server",
      "Storefront Scanner: 3/day",
      "14 Days Free Trial",
    ],
    planLevel: 1,
    plan_id_monthly: "P-9YM62790YM762253NM2727PI",
    plan_id_monthly_no_trial: "P-9YM62790YM762253NM2727PI",
    plan_id_annually: "P-7PC22834JX7377414M273AFI",
    plan_id_annually_no_trial: "P-7PC22834JX7377414M273AFI",
  },] : [
    {
      name: "Advanced",
      price_monthly: (urlParams.has("only34") || localStorage.getItem("only34") === "1") ? 34 : 78,
      price_annually: 62,
      img: "/assets/advancedFlower.png",
      checkList: [
        "Profitable products from Retail Suppliers (Walmart, Target, Kohl's, Zoro, Home Depot, etc.)",
        "Profitable products from more than 60 Unique Distributors",
        "Profitable products from Wholesale Suppliers",
        "Profitable products that are currently Out-of-Stock on Amazon",
        "Amazon Flips (A2A)",
        "Advanced Product Data",
        "Storefront Scanner: 6/day",
        "14 Days Free Trial",
      ],
      planLevel: 2,
      plan_id_monthly: (urlParams.has("only34") || localStorage.getItem("only34") === "1") ? "P-3P1040629L2418845M2Y7ICI" : "P-8FJ44572AR517302MM2MZQ2Y",
      plan_id_monthly_no_trial: (urlParams.has("only34") || localStorage.getItem("only34") === "1") ? "P-3P1040629L2418845M2Y7ICI" : "P-8FJ44572AR517302MM2MZQ2Y",
      plan_id_annually: "P-53X247570J723262AM2MZP4A",
      plan_id_annually_no_trial: "P-53X247570J723262AM2MZP4A",
    },
    {
      name: "Pro",
      price_monthly: 98,
      price_annually: 78,
      img: "/assets/proFlower.png",
      checkList: [
        "Everything from the previous plan",
        "Telegram, Bundles (Soon), Liquiditions (Soon)",
        "Amazon to Walmart (Walmart Sellers)",
        "Pro Data",
        "Pro Features: Filter By Profit, and Sort By 'Found Time'",
        "Export deals to CSV",
        "Storefront Scanner: 15/day",
        "14 Days Free Trial",
      ],
      planLevel: 3,
      plan_id_monthly: "P-6KY85952CP610184DM2MZO3I",
      plan_id_monthly_no_trial: "P-6KY85952CP610184DM2MZO3I",
      plan_id_annually: "P-23M620651E1919059M2MZN4I",
      plan_id_annually_no_trial: "P-23M620651E1919059M2MZN4I",
    },
    {
      name: "Master",
      price_monthly: urlParams.has("master") ? 75 : 112,
      price_annually: urlParams.has("master") ? 56 : 83,
      isEnterprise: true,
      img: "/assets/enterpriseflower.png",
      checkList: [
        "Everything from the previous plans",
        "CSV-Scanner - Find products from given CSV",
        "Nepeto Master Search!!!",
        "Storefront Scanner: 30/day",
        "You can prioritize adding specific suppliers to Nepeto",
        "Personal Nepeto's expert representative",
      ],
      planLevel: -1,
      plan_id_monthly: urlParams.has("master") ? "P-6B2209268W634562NM2MDZIQ" : "P-5C244369VX386000TM2LN65Q",
      plan_id_monthly_no_trial: urlParams.has("master") ? "P-6B2209268W634562NM2MDZIQ" : "P-5C244369VX386000TM2LN65Q",
      plan_id_annually: urlParams.has("master") ? "P-33P21230H14952948M2NJR4Y" : "P-2GR357408U5110239M2LN7OQ",
      plan_id_annually_no_trial: urlParams.has("master") ? "P-33P21230H14952948M2NJR4Y" : "P-2GR357408U5110239M2LN7OQ",
    },
  ];
  const [isMonthly, setIsMonthly] = useState(true);
  const [activePlanLevel, setActivePlanLevel] = useState(undefined);
  const [cancelSubModal, setCancelSubModal] = useState(false);
  if (urlParams.has("only34")) { 
    localStorage.setItem("only34", "1")
  }

  useEffect(() => {
    const getActivePlan = async () => {
      if (props.activePlanLevel === "Starter Plan") {
        setActivePlanLevel(1)
      } else if (props.activePlanLevel === "Advanced Plan") {
        setActivePlanLevel(2)
      } else if (props.activePlanLevel === "Pro Plan") {
        setActivePlanLevel(3)
      } else if (props.activePlanLevel === "Master Plan") {
        setActivePlanLevel(4)
      } else {
        setActivePlanLevel(parseInt(props.activePlanLevel))
      }
    };
    if (!isLoading && isAuthenticated) {
      getActivePlan();
    }
  }, [props.activePlanLevel, isLoading, isAuthenticated])

  return (
    <div className="container">
      <Helmet>
        <title>Nepeto - Pricing</title>
        <meta
          name="description"
          content="Explore our pricing options and choose the plan that best suits your needs. Discover transparent and competitive pricing for our products and services. Find the perfect pricing plan for your requirements."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, pricing, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div class="columns center">
        <span
          style={{
            position: "absolute",
            textAlign: "center",
            top: "3vh",
            fontSize: "200%",
            color: "rgb(140, 62, 124)",
          }}
        >
          <p onClick={() => window.open("https://calendly.com/igprojects/nepeto-tutorial-call")} style={{ cursor: "pointer", textDecoration: "underline", fontSize: props.isMobile ? "50%" : "70%", color: "slateblue" }}>Set a <strong style={{ color: "slateblue" }}>FREE</strong> call with an Amazon expert for a tutorial on secret sourcing methods using Nepeto</p>
        </span>
        <div class="column center" style={{ marginTop: props.isMobile ? "10vh" : "6vh", marginLeft: "5%" }}>
          <span
            style={{
              fontSize: "1.5rem",
              color: "rgb(140, 62, 124)",
              fontWeight: isMonthly && "bold",
              padding: "1rem",
              cursor: "pointer"
            }}
            onClick={() => setIsMonthly(!isMonthly)}
          >
            Monthly
          </span>{" "}
          <label class="switch">
            <input type="checkbox" onClick={() => setIsMonthly(!isMonthly)} checked={!isMonthly} />
            <span class="slider round"></span>
          </label>
          <span
            style={{
              fontSize: "1.5rem",
              color: "rgb(140, 62, 124)",
              fontWeight: !isMonthly && "bold",
              padding: "1rem",
              width: "30%",
              whiteSpace: "nowrap",
              cursor: "pointer"
            }}
            onClick={() => setIsMonthly(!isMonthly)}
          >
            Annually {props.isMobile && <br />}<span style={{ backgroundColor: "lightgreen", padding: "0% 5%", borderRadius: "30px", boxShadow: "0px 0px 5px 0px green" }}>{urlParams.has("raExplorer") ? "DISCOUNT!" : "20%+ OFF!"}</span>
          </span>{" "}
        </div>
      </div>
      {props.activePlanLevel.includes("Plan") && <div style={{ marginTop: "-3vh", textAlign: "center", fontSize: "125%" }}>
        Current Plan: {props.activePlanLevel}&nbsp; | &nbsp;{props.subscriptionActive === undefined ? "Loading..." : (props.subscriptionActive ? ("Next billing date: " + (props.nextBillingTime ? new Date(props.nextBillingTime).toLocaleDateString() : props.nextBillingTime)) : ("Cancelled, Access remains until: " + (props.nextBillingTime ? new Date(props.nextBillingTime).toLocaleDateString() : props.nextBillingTime)))}{props.subscriptionActive !== undefined && props.subscriptionActive && <>&nbsp;<button className="button" style={{ padding: "2px", height: "auto" }} onClick={() => setCancelSubModal(true)}>Cancel Subscription</button></>}
      </div>}
      <br />
      <div style={{ margin: "auto", marginBottom: "2%", marginLeft: props.isMobile ? "4%": "0.25%" }}>
        Are you looking for the FREE mobile app and Chrome extension?&nbsp;
        <a href="/app-ext">Click here for more information.</a>
          </div>
      <div className="columns">
        {prices.map((item) => {
          return <PriceCard plan={item} plan_id={item.plan_id} isMonthly={isMonthly} activePlanLevel={activePlanLevel} isMobile={props.isMobile}></PriceCard>;
        })}
      </div>

      <div class="columns">
        <div class="column">
          <h2
            style={{
              fontSize: "2vh",
              color: "#000000",
              textAlign: "center",
              marginBottom: "4vh",
            }}
          >
            Bring your friends and earn <strong>30%</strong> monthly! <a href="/affiliate" alt="affiliate-program" target="_blank" rel="noreferrer">Click here to join our affiliate program today!</a>
            <br />
            Want to learn more? <a href="/how-to-use-nepeto" alt="how-to-use-nepeto" target="_blank" rel="noreferrer">Click here to see how to use Nepeto</a>
            <br />
            Subscriptions can be canceled at any time. Click&nbsp;
            <span style={{ color: "slateblue", cursor: "pointer" }} onClick={() => { setCancelSubModal(true) }}>
              here
            </span>
            &nbsp;to cancel.
            <br />
            If you have any questions, please don't hesitate to{" "}
            <Link to="/contact">contact us</Link> or check our{" "}
            <Link to="/faq">FAQ</Link>.
            <br />
            By starting a subscription, you acknowledge and accept our{" "}
            <a href="/termsandpolicies.pdf">Terms and Conditions</a>.
          </h2>
        </div>
      </div>
      {cancelSubModal && <CancelSubModal setCancelSubModal={setCancelSubModal} />}
    </div>
  );
}
export default Pricing;
