import Carousel from "./components/CarouselV2";
import "./Home.css";
import ContactUsBlock, { IconDiscord } from "./components/ContactUsBlock";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUpFreeTrialButton from "./components/SignUpFreeTrialButton";

function Home(props) {
  const navigate = useNavigate();
  const [imgSrcs, setImgSrcs] = useState(["1", "2", "3", "4"]);

  useEffect(() => {
    const numOfLogosAvailable = 65;
    const interval = setInterval(() => {
      const randomIntegers = [];

      while (randomIntegers.length < 4) {
        const random = Math.floor(Math.random() * numOfLogosAvailable) + 1;
        if (!randomIntegers.includes(random)) {
          randomIntegers.push(random);
        }
      }

      setImgSrcs(randomIntegers);
    }, 2000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  return (
    <>
      <Helmet>
        <title>Nepeto - Automated Product Sourcing for Amazon</title>
        <meta
          name="description"
          content="Our algorithm scans hundreds of retailers, distributors, and wholesale suppliers, providing you with a selection of thousands of profitable products ready for resell on Amazon!"
        />
        <meta
          name="keywords"
          content="amazon fba, amazon, online arbitrage, amazon sourcing, fba products, profitable products, nepeto"
        />
      </Helmet>
      {!props.isMobile && (<>
        <img
          alt="logo"
          src={`/assets/logos/${imgSrcs[0]}.png`}
          className="popupLogo"
          loading="lazy"
          style={{ top: "25%", left: "10%", zIndex: "-1" }}
        />
      <img
        alt="logo"
        src={`/assets/logos/${imgSrcs[1]}.png`}
        className="popupLogo"
        style={{
          top: !props.isMobile ? "15%" : "17%",
          left: "15%",
          zIndex: "-1",
        }}
        loading="lazy"
      />
      <img
        alt="logo"
        src={`/assets/logos/${imgSrcs[2]}.png`}
        className="popupLogo"
        loading="lazy"
        style={{
          top: !props.isMobile ? "25%" : "17%",
          left: !props.isMobile ? "86%" : "75%",
          zIndex: "-1",
        }}
      />
      <img
        alt="logo"
        src={`/assets/logos/${imgSrcs[3]}.png`}
        className="popupLogo"
        loading="lazy"
        style={{
          top: !props.isMobile ? "15%" : "17%",
          left: !props.isMobile ? "81%" : "45%",
          zIndex: "-1",
        }}
      /></>)}

      <div
        className="mx-5 aharoni"
        style={{
          marginTop: props.isMobile ? "10%" : "3%",
          marginBottom: props.isMobile ? "30%" : "3.5%",
          textAlign: !props.isMobile && "center",
        }}
      >
        <p
          className="is-size-2-mobile is-size-1-tablet"
          style={{ color: "#8c3e7c" }}
        >
          <strong style={{ color: "#8c3e7c" }}>Automated</strong> Product
          Sourcing for Amazon
        </p>
        <p
          className="is-size-6-mobile is-size-5-tablet"
          style={{ color: "#8c3e7c" }}
        >
          Our algorithm scans hundreds of{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
              cursor: "pointer",
            }}
            onClick={() => navigate("/retail")}
          >
            retailers
          </span>
          , unique{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
              cursor: "pointer",
            }}
            onClick={() => navigate("/distributors")}
          >
            distributors
          </span>
          , and{" "}
          <span
            style={{
              borderBottom: "1px solid #e6cede",
              color: "#8c3e7c",
              cursor: "pointer",
            }}
            onClick={() => navigate("/wholesale")}
          >
            wholesale
          </span>{" "}
          suppliers,
          <br />
          providing you with a selection of thousands of{" "}
          <span style={{ backgroundColor: "#e6cede", color: "#8c3e7c" }}>
            profitable products
          </span>{" "}
          ready for resell on Amazon!{" "}
        </p>
        <br />
        <div className="container custom-carousel" style={{ marginTop: "2vh" }}>
          <Carousel isMobile={props.isMobile} />
        </div>
        <br />

        {/* Sign Up button */}
        {props.isMobile && <br />}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SignUpFreeTrialButton
            fontSize={props.isMobile ? "2vh" : "calc(1vw + 1vh)"}
          />
        </div>
      </div>
          
      {/* The Future is Nepeto -- Mobile */}
      {props.isMobile &&
          <iframe
            src="https://www.youtube-nocookie.com/embed/wzvMK2UiHGg?si=pDDNQIWGR-SDIg-U"
            title="YouTube video player"
            style={{
              margin: "auto",
              marginLeft: "5%",
              border: "2px solid #8c3e7c",
              padding: "1%",
              width: props.isMobile ? "90%" : "35vw",
              height: props.isMobile ? "50vw" : "23vw",
            }}
            frameborder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
      }

      {!props.isMobile && (
        <>
          {/* The Future is Nepeto -- Desktop */}
          <div
            className="columns"
            style={{
              height: props.isMobile ? "100vh" : "90vh",
              width: props.isMobile ? "90%" : "80%",
              margin: "auto",
              marginTop: props.isMobile ? "5vh" : "25vh",
            }}
          >
            <div className="column">
              <iframe
                src="https://www.youtube-nocookie.com/embed/wzvMK2UiHGg?si=pDDNQIWGR-SDIg-U"
                title="YouTube video player"
                style={{
                  margin: "auto",
                  border: "2px solid #8c3e7c",
                  padding: "1%",
                  width: props.isMobile ? "100%" : "35vw",
                  height: props.isMobile ? "50vw" : "23vw",
                }}
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="column">
              <p
                className="aharoni"
                style={{
                  color: "#8c3e7c",
                  fontSize: props.isMobile ? "5vh" : "2.75vw",
                }}
              >
                The Future is Nepeto
              </p>
              <hr style={{ borderTop: "5px dashed", color: "#8c3e7c" }} />
              <div className="montserrat">
                <strong
                  style={{
                    color: "#8c3e7c",
                    fontSize: props.isMobile ? "2.2vh" : "1.2vw",
                  }}
                >
                  With our unique technology, we source the most profitable and
                  top-selling products from hundreds of suppliers for your
                  Amazon store, providing you with a straightforward and
                  efficient solution, without wasting time or money. Our
                  technology have the ability to scan all-sizes suppliers, even
                  the big ones like Walmart, Kohl's, Zoro and Home Depot!
                </strong>
                <br />
                <br />
                <img
                  alt="logo"
                  src={`/assets/logos/${imgSrcs[0]}.png`}
                  loading="lazy"
                  style={{
                    width: props.isMobile ? "10vw" : "3vw",
                    height: props.isMobile ? "10vw" : "3vw",
                    objectFit: "contain"
                  }}
                />
                <img
                  alt="logo"
                  src={`/assets/logos/${imgSrcs[1]}.png`}
                  loading="lazy"
                  style={{
                    width: props.isMobile ? "10vw" : "3vw",
                    height: props.isMobile ? "10vw" : "3vw",
                    marginLeft: "4vw",
                    objectFit: "contain"
                  }}
                />
                <img
                  alt="logo"
                  src={`/assets/logos/${imgSrcs[2]}.png`}
                  loading="lazy"
                  style={{
                    width: props.isMobile ? "10vw" : "3vw",
                    height: props.isMobile ? "10vw" : "3vw",
                    marginLeft: "4vw",
                    objectFit: "contain"
                  }}
                />
                <img
                  alt="logo"
                  src={`/assets/logos/${imgSrcs[3]}.png`}
                  loading="lazy"
                  style={{
                    width: props.isMobile ? "10vw" : "3vw",
                    height: props.isMobile ? "10vw" : "3vw",
                    marginLeft: "4vw",
                    objectFit: "contain"
                  }}
                />
                {!props.isMobile && (
                  <>
                    <br />
                    <br />
                    <a
                      class="button"
                      href="https://discord.gg/jg2vmgMrz9"
                      style={{
                        padding: props.isMobile ? "7% 4%" : "4%",
                        backgroundColor: "#e6cede",
                        borderRadius: "50px",
                        color: "#8c3e7c",
                        fontSize: "115%",
                        zIndex: "100",
                      }}
                    >
                      Join our &nbsp; <IconDiscord /> Community !
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Why use Nepeto? -- Desktop */}
          <div
            className="container is-fluid center"
            style={{
              backgroundColor: "#8c3e7c",
              height: props.isMobile ? "120vh" : "100vh",
              marginTop: props.isMobile && "10vh",
            }}
          >
            <div
              className="container"
              style={{
                marginTop: props.isMobile && "22vh",
                marginBottom: props.isMobile && "5vh",
                fontSize: props.isMobile ? "5vh" : "3.2vw",
              }}
            >
              <div className="columns">
                <div className="column has-text-centered">
                  <p className="aharoni white">Why use Nepeto?</p>
                </div>
              </div>

              <div className="columns has-text-centered spartan is-desktop">
                <div className="column">
                  <div className="columns">
                    <div className="column is-4-mobile is-offset-4-mobile is-4-tablet is-offset-4-tablet is-three-fifths-desktop is-offset-one-fifth-desktop">
                      <div className="circle" style={{ margin: "auto" }}>
                        <img src={"/assets/thumbs-up.PNG"} alt="Thumbs up" />
                      </div>
                    </div>
                  </div>
                  <p
                    className="white"
                    style={{ fontSize: props.isMobile ? "3.5vh" : "2.2vw" }}
                  >
                    Trustworthy
                  </p>
                  <p
                    className="white"
                    style={{ fontSize: props.isMobile ? "2.5vh" : "1.5vw" }}
                  >
                    Nepeto provides transparent and accessible information for anyone to check
                    <br />
                    <br />
                  </p>
                </div>

                <div className="column">
                  <div className="columns">
                    <div className="column is-4-mobile is-offset-4-mobile is-4-tablet is-offset-4-tablet is-three-fifths-desktop is-offset-one-fifth-desktop">
                      <div className="circle" style={{ margin: "auto" }}>
                        <img src={"/assets/wallet.PNG"} alt="Wallet" />
                      </div>
                    </div>
                  </div>
                  <p
                    className="white"
                    style={{ fontSize: props.isMobile ? "3.5vh" : "2.2vw" }}
                  >
                    Affordability
                  </p>
                  <p
                    className="white"
                    style={{ fontSize: props.isMobile ? "2.5vh" : "1.5vw" }}
                  >
                    Thousands of deals in one subscription
                    <br />
                    <br />
                  </p>
                </div>

                <div className="column">
                  <div className="columns">
                    <div className="column is-4-mobile is-offset-4-mobile is-4-tablet is-offset-4-tablet is-three-fifths-desktop is-offset-one-fifth-desktop">
                      <div className="circle" style={{ margin: "auto" }}>
                        <img
                          src={"/assets/puzzle.PNG"}
                          alt="Puzzle"
                          style={{ width: "65%", height: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                  <p
                    className="white"
                    style={{ fontSize: props.isMobile ? "3.5vh" : "2.2vw" }}
                  >
                    Efficency
                  </p>
                  <p
                    className="white mb-3"
                    style={{ fontSize: props.isMobile ? "2.5vh" : "1.5vw" }}
                  >
                    We find the products for you, focus on selling
                    <br />
                    <br />
                  </p>
                </div>
              </div>

              {/* Sign Up button -- Desktop */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: props.isMobile && "10vh",
                }}
              >
                <SignUpFreeTrialButton
                  fontSize={props.isMobile ? "2vh" : "calc(1vw + 1vh)"}
                />
              </div>
              <br />
            </div>
          </div>

          {/* Some Common Questions -- Desktop */}
          <div
            className="container is-fluid center"
            style={{
              height: props.isMobile ? "112vh" : "100vh",
              marginTop: props.isMobile && "12vh",
            }}
          >
            <div className="container spartan">
              <div className="columns is-mobile">
                <div className="column is-4-mobile is-offset-1-mobile is-5-tablet is-offset-2-tablet is-offset-0-desktop">
                  <p
                    className="custom-title"
                    style={{
                      color: "#8b3c7e",
                      fontSize: props.isMobile ? "4vh" : "3.5vw",
                    }}
                  >
                    Commonly Asked Questions
                  </p>
                </div>
                <div className="column is-5-mobile is-offset-2-mobile is-4-tablet is-2-desktop is-offset-2-desktop">
                  <img
                    src={"/assets/qmark.PNG"}
                    alt="qmark"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>

              <div className="columns is-desktop">
                <div className="column is-10-mobile is-offset-1-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-0-desktop">
                  <div className="box">
                    <div className="row">
                      <p className="custom-text" style={{ color: "#8b3c7e" }}>
                      What is the difference between Nepeto and a lead list/VAs?
                      </p>
                    </div>
                    <div
                      className="row"
                      style={{
                        borderTop: "3px dashed #8b3c7e",
                        paddingTop: "25px",
                      }}
                    >
                      <p className="small-text">
                      We do not manually source products. Our algorithm scans hundreds of suppliers and finds thousands of profitable and selling products. Because of that, we have the ability for exclusivity.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="column is-10-mobile is-offset-1-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-0-desktop">
                  <div className="box">
                    <div className="row">
                      <p className="custom-text" style={{ color: "#8b3c7e" }}>
                      What do you do about competition for your products?
                      </p>
                    </div>
                    <div
                      className="row"
                      style={{
                        borderTop: "3px dashed #8b3c7e",
                        paddingTop: "25px",
                      }}
                    >
                      <p className="small-text">
                      We understand how frustrating it is to face competition that cuts the profits. Therefore, you can exclusively have the products for yourself and hide them from other Nepeto users!
                      </p>
                    </div>
                  </div>
                </div>

                <div className="column is-10-mobile is-offset-1-mobile is-8-tablet is-offset-2-tablet is-4-desktop is-offset-0-desktop">
                  <div className="box">
                    <div className="row">
                      <p className="custom-text" style={{ color: "#8b3c7e" }}>
                      How to use Nepeto?
                      </p>
                    </div>
                    <div
                      className="row"
                      style={{
                        borderTop: "3px dashed #8b3c7e",
                        paddingTop: "25px",
                      }}
                    >
                      <p className="small-text">
                        {" "}
                        Select a product, visit the supplier's website, and purchase the product. It's as simple as that.<br/>
                        <a href="/how-to-use-nepeto" alt="csv-export" target="_blank" rel="noreferrer">Click here to see how to use Nepeto</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Past Clients - Both desktop & mobile */}
      <div
        className="container is-fluid center"
        style={{
          backgroundColor: "#8b3c7e",
          height: props.isMobile ? "135vh" : "100vh",
          marginTop: props.isMobile && "20vh",
        }}
      >
        <div className="container">
          <div className="columns">
            <div
              className="column is-offset-1-mobile is-offset-1-tablet"
              style={{ fontSize: props.isMobile ? "4.5vh" : "3vw" }}
            >
              <p className="custom-title spartan white">Past Clients</p>
            </div>
          </div>

          <div className="columns">
            <div className="column is-8-mobile is-offset-2-mobile is-4-tablet is-offset-1-tablet">
              <iframe
                src="https://www.youtube.com/embed/IMhu-TsZzUA"
                title="YouTube video player"
                style={{
                  margin: "auto",
                  border: "2px solid #8c3e7c",
                  padding: "1%",
                  width: "100%",
                  height: props.isMobile ? "40vh" : "100%",
                }}
                frameborder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="column is-10-mobile is-offset-1-mobile is-6-tablet is-offset-1-tablet is-5-desktop has-text-weight-bold">
              <p
                className="spartan white"
                style={{ fontSize: props.isMobile ? "4vh" : "2.2vw" }}
              >
                Trevor Harris
              </p>
              <p
                className="white"
                style={{
                  color: "#e6cede",
                  fontSize: props.isMobile ? "2.2vh" : "1.1vw",
                }}
              >
                "Nepeto has transformed the way I source products on Amazon.
                It's incredibly efficient and user-friendly. I've seen a
                noticeable boost in my sales and profit margins since I started
                using it."
              </p>
              <br />
              <p
                className="spartan white"
                style={{ fontSize: props.isMobile ? "4vh" : "2.2vw" }}
              >
                James Okafor
              </p>
              <p
                className="white"
                style={{
                  color: "#e6cede",
                  fontSize: props.isMobile ? "2.2vh" : "1.1vw",
                }}
              >
                "As an Amazon seller, Nepeto has become my secret weapon. It
                simplifies product research and helps me find profitable items."
              </p>
              <br />
              <p
                className="spartan white"
                style={{ fontSize: props.isMobile ? "4vh" : "2.2vw" }}
              >
                Emily Davis
              </p>
              <p
                className="white"
                style={{
                  color: "#e6cede",
                  fontSize: props.isMobile ? "2.2vh" : "1.1vw",
                }}
              >
                "Nepeto is a real time-saver! It streamlines the sourcing
                process, saving me hours of work."
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* LET'S WORK TOGETHER */}
      <ContactUsBlock homePage={true} />
    </>
  );
}

export default Home;
