export const retailLogos = {
  "walmart": 3,
  "hardwareandmoreee": 11,
  "cmple": 10,
  "homedepot": 1,
  "zoro": 2,
  "target": 4,
  "gamestop": 13,
  "bestbuy": 12,
  "barnesandnoble": 40,
  "fatbraintoys": 15,
  "maxwarehouse": 16,
  "macys": 14,
  "kohls": 8,
  "dickssportinggoods": 18,
  "kiehls": 17,
  "samsclub": 21,
  "acmetools": 20,
  "vitacost": 22,
  "lowes": 23,
  "farmandfleet": 25,
  "bjs": 24,
  "dollargeneral": 26,
  "boscovs": 27,
  "cvs": 28,
  "chewy": 29,
  "rarebeauty": 41,
  "jcpenney": 30,
  "bhphotovideo": 31,
  "academy": 32,
  "ecosmetics": 33,
  "truevalue": 34,
  "amerilifevitamin": 37,
  "petsmart": 35,
  "dkhardware": 36,
  "zappos": 38,
  "kmart": 39,
  "acehardware": 42,
  "b2bsportswear": 43,
  "bathandbodyworks": 44,
  "gisou": 45,
  "kvdveganbeauty": 46,
  "milkmakeup": 47,
  "nyfragrancesupply": 48,
  "oseamalibu": 49,
  "patrickta": 50,
  "shopwss": 51,
  "frontiercoop": 52,
  "everythingkitchens": 53,
  "hardwareandtools": 54,
  "michaels": 55,
  "officedepot": 56,
  "pureformulas": 57,
  "bedbathandbeyond": 58,
  "fragrancenet": 59,
  "fleetfarm": 60,
  "fivebelow": 61,
  "nike": 62,
  "belk": 63,
  "snipesusa": 64,
  "underarmour": 65,
  "crocs": 66, 
  "heb": 68, 
  "sephora": 69, 
  "yankeecandle": 70,
  "champssports": 71,
  "gnc": 72,
  "cosmetixclub": 80,
  "empirediscount": 81,
  "famousfootwear": 82,
  "findtape": 83,
  "glwholesale": 84,
  "hjcloseouts": 85,
  "honeysplace": 86,
  "iherb": 87,
  "koleimports": 88,
  "leedistributors": 89,
  "lvdistribution": 90,
  "mazaldistribution": 91,
  "nexdeal": 92,
  "palletfly": 93,
  "regentproducts": 94,
  "savoyactive": 95,
  "shoppremiumoutlets": 96,
  "somethingdifferentwholesale": 97,
  "ssisports": 98,
  "tjsgroupllc": 99,
  "topdawg": 100,
  "utnwholesale": 101,
  "webstaurantstore": 102,
  "weinersltd": 103,
  "twhouse": 105,
  "4sgm": 106,
  "888lots": 107,
  "advancedistributors": 108,
  "b-gsales": 109,
  "beautyjoint": 113,
  "bulkbuyamerica": 114,
  "discountwholesalersinc": 115,
  "dollartree": 116,
  "dpciwholesale": 117,
  "adosurprise": 118,
  "ecomwholesaledeals": 119,
  "greatdiscounters": 120,
  "gsp": 121,
  "hacknabra": 122,
  "kaywholesaledeals": 123,
  "kikowireless": 124,
  "kntradingllc": 125,
  "medistributors": 126,
  "megagoods": 127,
  "minmaxdeals": 128,
  "nat-procurement": 129,
  "northandsouthwholesalers": 130,
  "wholesale-perfume": 131,
  "topperliquidators": 132,
  "joann": 133, 
  "officesupply": 134, 
  "rei": 135, 
  "restaurantsupply": 136, 
  "riteaid": 137, 
  "vitaminshoppe": 138,
  "walgreens": 139,
  "shoprite": 140,
  "big5sportinggoods": 141,
  "dillards": 142,
  "kroger": 143,
  "haircareandbeauty": 144,
  "altomusic": 145,
  "annscottage": 146,
  "aerosoles": 147,
  "1000bulbs": 148
}